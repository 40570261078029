import secureStorage from '@src/config/encrypt';

//GET COUNTRY CODE FROM ENV   
let countryCode = process.env.REACT_APP_COUNTRY_CODE;
let appLanguage = process.env.REACT_APP_DEFAULT_LANG;

if (!secureStorage.getItem('lang')) {
    secureStorage.setItem('lang', appLanguage)
}

//IMPORT COUNTRY CONSTANTS
var path = `./${countryCode}_config/constant`;

var req = require.context("./", true);
var constants = req(path);


//SET COUNTRY CODE IN STORAGE
secureStorage.setItem('countryCode', countryCode);

const CONFIG = {};
CONFIG.constants = constants;

//SET GATEWAY URL
let REACT_APP_GETWAY_API = process.env.REACT_APP_GETWAY_API;

CONFIG.API_URL = {
    GETWAY_API: REACT_APP_GETWAY_API,
    LANG: appLanguage
}

export default CONFIG;
