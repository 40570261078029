import React, { useState, useEffect } from "react";
import { NavLink } from 'react-router-dom';
import LogoImg from "@src/webroot/images/carmudi-logo.svg";
import secureStorage from "@src/config/encrypt";
import { withTranslation } from 'react-i18next';
import { toast } from "react-toastify";
import * as HELPER from "@src/config/helper";
import { useTranslation } from "react-i18next";
//import { default as config } from '@src/config/config';
//import { useNavigate } from 'react-router';
import { useNavigate } from "react-router-dom";


const Navigation = (props) => {

    //const [isMount, setIsMount] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [name, setName] = useState("");
    const [role, setRole] = useState("");
    let navigate = useNavigate();
    const { t } = useTranslation("common");

    useEffect(() => {
        //setIsMount(true);
        let result = secureStorage.getItem("authUserInfo");

        if (!result) {
             setRedirect(true)
        } else {
            let userInfo = result.user_data;
            setName((userInfo && userInfo.first_name) || 'User');
            setRole(userInfo && userInfo.role_title);

        }
    },[]);

    // const changeLanguage = (lang) => {
    //     if (isMount) {
    //         secureStorage.setItem('lang', lang);
    //         props.i18n.changeLanguage(lang)
    //     }
    // }
    const logOutUser = async () => {
        let result = secureStorage.getItem("authUserInfo")
        if (result) {
            secureStorage.removeItem("authUserInfo")
            toast.success(t("FORM.LOGOUT"))
            setTimeout(()=> { navigate('/login');  },100)
        }
    };

    const checkVisibleMenu = (menus) => {
        return menus.filter(menu => menu.show_in_menu)
    }

    const linkUrl = (menu) => {
        let menuLink = '#'
        if (menu['action']) {
            menuLink = '/' + menu['action']
        }
        return menuLink
    }

    const getCurrentClass = (menuData) => {
        let currentPath = window.location.pathname.split('/');
        let activeClass = '';
        currentPath = currentPath.filter(path => path)
        if (currentPath.length) {
            let stringMenuData = JSON.stringify(menuData)
            let checkActiveUrl = HELPER.isUserHasAccessPage('', currentPath[0], stringMenuData)
            if (checkActiveUrl) activeClass = 'active';
        } else {
            if (menuData.name === 'Dashboard') {
                activeClass = 'active';
            }
        }
        return activeClass;
    }


    //let language = secureStorage.getItem("lang") === "id" ? "ID" : "EN";
    const authUserInfo = secureStorage.getItem('authUserInfo') || {};

    let isAuthorized = (authUserInfo && authUserInfo['headerMenu']) ? HELPER.isUserHasAccessPage() : true
    if (redirect || !isAuthorized) {
        secureStorage.removeItem("authUserInfo");
        // return <Redirect to="/login" />;
        navigate("/login"); 
        return null; 
    }


    const visibleMenus = authUserInfo && authUserInfo.user_data && authUserInfo.user_data.visibleMenu ? JSON.parse(authUserInfo.user_data.visibleMenu) : [] 
    

    return (
        <div className="header-outer-fixed">

            <div className="container-fluid" >
                <div className="header-main">
                    <div className="logo">
                    <NavLink to={'/dashboard'}>
                        <img src={LogoImg} className="" alt='inspection oto' />
                    </NavLink>
                    </div>
                    <div className="right-panel-naviganion menupanel">
                        <div className="nav">
                            <ul>
                                {
                                    visibleMenus && (visibleMenus).length
                                        ?
                                        (visibleMenus).map((el, i) => {
                                            let childMenu = ''

                                            if(el.name === 'USERS') childMenu = 'USER_MODULE.'
                                            if(el.name === 'INSPECTION') childMenu = 'INSPECTION.'
                                            if(el.name === 'BACK_OFFICE') childMenu = 'BACK_OFFICE.'
                                            if(el.name === 'ROSTER') childMenu = 'ROSTER.'

                                            return (<li className={el.childMenu.length && !el['action'] ? 'dropdownmenu' : ''} key={i}>

                                                {/* <NavLink to={linkUrl(el)} activeClassName={getCurrentClass(el)}> */}
                                                <NavLink to={linkUrl(el)} className={getCurrentClass(el)}>
                                                    {props.t('MENU.' + (el.name).replace(' ', '_'))}
                                                </NavLink>

                                                <ul className="submenu" key={i}>
                                                    {
                                                        checkVisibleMenu(el['childMenu']).map((elm, j) => {
                                                            let childMenuName = elm['name'].replace(' ', '_')
                                                            return (<li className="level2" key={j}>
                                                                
                                                                <NavLink to={linkUrl(elm)} className={getCurrentClass(elm)}>{props.t(childMenu + childMenuName)}</NavLink>

                                                            </li>)
                                                        })
                                                    }
                                                </ul>
                                            </li>)

                                        })
                                        :
                                        (
                                            <li></li>
                                        )
                                }

                            </ul>
                           
                        </div>

                        <div className="user-detail ">
                            <ul>

                                <li className="dropdownmenu">
                                    <NavLink to="#">
                                        <span className="userarc">
                                            {name.length && name[0].toUpperCase()}
                                        </span>
                                        <div className="username">
                                            <span>{name}</span>
                                            <span className="user-degintion">{role || 'Admin'}</span>
                                        </div>
                                    </NavLink>
                                    <ul className="submenu">
                                        <li><NavLink to="#"  onClick={logOutUser}>Log Out</NavLink> </li>
                                    </ul>
                                </li>

                            </ul>
                        </div>


                        {/* {
                            config.constants.language.length >= 1 &&
                            <div className="language">
                                <ul>
                                    <li className="dropdownmenu">
                                        <NavLink to="#">
                                            {language}
                                        </NavLink>
                                        <ul className="submenu lang-menu">

                                            {
                                                config.constants.language.map((lang, index) => {
                                                    return <li key={index}>
                                                        <NavLink to="#" onClick={() => changeLanguage(lang.iso_code)}>{lang.key}</NavLink>
                                                    </li>
                                                })
                                            }
                                        </ul>
                                    </li>

                                </ul>
                            </div>

                        } */}
                        
                    </div>

                </div>

            </div >
        </div>
    );
}

export default withTranslation('common')(Navigation); 